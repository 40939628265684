.brandsSec {
    padding: 30px 0 50px;
}
.brandsItem ul{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.brandsItem ul li a {
    display: flex;
    background: #ffffff;
    padding: 35px;
    text-align: center;
    box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
    height: 137px;
    justify-content: center;
    width: 100%;
}

